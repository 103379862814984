<template>
  <b-modal
    id="conditional-node-modal"
    :title="`${form.uuid ? 'Update' : 'Add'} Conditional Node Properties`"
    :ok-title="`${form.uuid ? 'Update' : 'Add'}`"
    size="xl"
    no-close-on-backdrop
    static
    @ok="handleOk"
    @shown="populateForm"
    @hidden="conditionalNodeForm.reset()"
  >
    <validation-observer ref="conditionalNodeForm">
      <b-overlay :show="isProcessing">
        <b-row>
          <b-col md="6">
            <!-- Description -->
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-group label="Description">
                <b-form-textarea
                  v-model="form.description"
                  placeholder="Explain what this node does"
                  :rows="form.uuid ? 8 : 4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

          </b-col>

          <b-col md="6">
            <!-- Name -->
            <validation-provider
              v-if="form.uuid"
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group label="Name">
                <b-form-input
                  v-model="form.name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Condition -->
            <validation-provider
              v-slot="{ errors }"
              name="condition"
              rules="required"
            >
              <b-form-group label="Condition">
                <b-form-input
                  v-model="form.condition"
                  placeholder="Write your condition here"
                  @change="changeVariables"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Data -->
            <!-- <validation-provider
              v-slot="{ errors }"
              name="data"
            >
              <b-form-group>
                <template #label>
                  <span>Data</span>
                  <feather-icon
                    v-b-tooltip.hover="'While performing single run, enter the data to replicate passed down data from the parent node'"
                    icon="InfoIcon"
                    :style="'margin-left: 4px'"
                  />
                </template>
                <b-form-input
                  v-model="form.data"
                  placeholder="Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider> -->
          </b-col>
        </b-row>

        <!-- Params -->
        <div v-if="form.params.length">
          <h5 class="mt-2">
            Params:
          </h5>
          <b-row>
            <b-col
              v-for="param, index of form.params"
              :key="param.name"
              md="6"
              class="border-bottom py-1"
            >
              <b-row class="align-items-center">
                <b-col md="5">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="'name'"
                    rules="required"
                  >
                    <b-form-input
                      v-model="param.name"
                      placeholder="Param name"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="value"
                    rules="required"
                  >
                    <b-form-input
                      v-model="param.value"
                      placeholder="Param value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <feather-icon
                    class="text-danger cursor-pointer"
                    icon="Trash2Icon"
                    @click="removeParam(index)"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <!-- <b-button
          variant="primary"
          @click="() => { form.params.push({ name: '', value: '' }) }"
        >
          Add Params
        </b-button> -->
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script setup>
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow, BCol, BModal, BOverlay, BFormGroup, BFormInput, BFormTextarea,
  // VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { getCurrentInstance, ref } from 'vue'
import { NODE_TYPES } from '../nodeConstants'

// const vBTooltip = VBTooltip

const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  node: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits('node-properties', 'refetch')

const root = getCurrentInstance().proxy
const conditionalNodeForm = ref()
const isProcessing = ref(false)
const form = ref({
  params: [],
})
const variables = []

// Methods
const populateForm = () => {
  const { model, ...properties } = props.properties
  form.value = {
    params: [],
    ...properties,
  }
  if (props.node.uuid) form.value.name = props.node.name
}

const removeParam = index => {
  form.value.params.splice(index, 1)
}

const changeVariables = () => {
  if (form.value.condition) {
    const regex = /\{\{([^}]+)\}\}/g
    let inputVariable

    // eslint-disable-next-line no-cond-assign
    while ((inputVariable = regex.exec(form.value.condition)) !== null) {
      const variable = inputVariable[1]
      if (variables.indexOf(variable) === -1) variables.push(variable)
      if (!form.value.params.find(param => param.name === variable)) {
        variables.push(variable)
        form.value.params.push({
          name: variable,
          value: '',
        })
      }
    }

    const formVariables = new Set(form.value.params.map(param => param.name))
    const inputVariables = new Set(variables)

    const removedVariables = formVariables.difference(inputVariables)
    removedVariables.forEach(variable => {
      const index = form.value.params.findIndex(param => param.name === variable)
      form.value.params.splice(index, 1)
    })
  }
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  conditionalNodeForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true

      const { data, ...formData } = form.value
      if (data) formData.data = data
      emit('node-properties', formData)

      if (formData.uuid) {
        const payload = {
          ...props.node,
          ...formData,
          node_type: NODE_TYPES.conditional,
        }

        useJwt.updateNode(payload).then(response => {
          root.showSuccessMessage(response)
        }).finally(() => {
          root.$bvModal.hide('conditional-node-modal')
          isProcessing.value = false
          // emit('refetch')
        })
      } else {
        root.$bvModal.hide('conditional-node-modal')
        isProcessing.value = false
      }
    }
  })
}
</script>
