<template>
  <b-modal
    id="drawflow-guide-modal"
    title="Drawflow Guide"
    size="lg"
    ok-title="Close"
    ok-only
    static
  >
    <div class="d-flex align-items-start">
      <img
        :src="require('@/assets/images/icons/left-click.svg')"
        class="mr-1"
        width="18px"
        alt=""
      >
      <p><kbd class="text-dark">Left click</kbd> on a node to select or drag a node.</p>
    </div>

    <div class="d-flex align-items-start">
      <img
        :src="require('@/assets/images/icons/right-click.svg')"
        class="mr-1"
        width="18px"
        alt=""
      >
      <p><kbd class="text-dark">Right click</kbd> on a node to show remove option.</p>
    </div>

    <div class="d-flex align-items-start">
      <feather-icon
        icon="Trash2Icon"
        class="text-danger mr-1"
        size="20"
      />
      <p>Select a node or connection and press <kbd class="bg-light-danger">Delete</kbd> key to remove it.</p>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
}
</script>
